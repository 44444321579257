@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px auto 120px;
  max-width: $homepage-max-width;

  @include media-breakpoint-up(lg) {
    margin: 60px auto 140px;
  }

  @include media-breakpoint-down(md) {
    margin: 60px auto 120px;
  }
}
