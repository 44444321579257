@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.container {
  box-sizing: border-box;
  width: 100%;
  max-width: 1440px;
}

.heading {
  color: $snow;
  font-family: $harrybeastdisplay;
  font-size: 38.875px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.777px;
  padding: 0 25px;
  text-transform: uppercase;
  @include media-breakpoint-down(lg) {
    text-align: center;
    margin-bottom: 30px;
    padding: 0 20px;
  }
  @include media-breakpoint-down(md) {
    font-size: 32px;
  }
}

.checklistContainer {
  box-sizing: border-box;
  width: 100%;
  display: flex;
}

.loadedContainer {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  width: 100%;
  position: relative;
  margin-top: -32px; // aligns header to match other components on homepage

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    gap: 70px;
  }
}

.left,
.right {
  display: flex;
  box-sizing: border-box;
  justify-content: space-around;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  padding: 0 20px;
  @include media-breakpoint-down(lg) {
    justify-content: center;
    flex-wrap: wrap;
  }
}

.left {
  flex-basis: 60%;
}

.right {
  flex-basis: 40%;
  position: relative;
  justify-content: flex-start;
  .divider {
    position: absolute;
    left: -10px;
    top: 55%;
    transform: translateY(-50%);
    width: 16px;
    height: 85%;
    background-image: var(--vertical-divider);
    background-position: center;
    background-repeat: repeat;
    @include media-breakpoint-down(lg) {
      width: 100%;
      height: 16px;
      top: -30px;
      left: 0;
      background-image: var(--horizontal-divider);
      background-repeat: no-repeat;
    }
  }

  @include media-breakpoint-down(lg) {
    justify-content: center;
  }
}

.checkListItem {
  &:not(.noHover) {
    &:hover {
      a {
        color: $purple;
      }
    }
  }
}

.completedContainer {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .2s ease-out transform;

  &:not(.noCursor):hover {
    cursor: pointer;
  }

  .ellipse {
    width: 70%;
    height: 70%;
    border-radius: 50%;
    position: relative;
    transition: all .2s ease-out;
    &.gryffindor {
      background-color: $gryffindorGrad1;
    }
    &.hufflepuff {
      background-color: $hufflepuffGrad1;
    }
    &.ravenclaw {
      background-color: $ravenclawGrad1;
    }
    &.slytherin {
      background-color: $slytherinGrad1;
    }

    &.patronus {
      background-color: #1f2e3f;
    }
    &.generic {
      position: relative;
      z-index: 1;
      background: linear-gradient(91deg, #5D4586 20.47%, #4E327A 62.03%, #413688 105.35%);
    }
  }

  .houseCrest {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 110%;
    height: 110%;
    z-index: 2;
    background-position: center;
    background-size: 90%;
    background-repeat: no-repeat;
    &.gryffindor {
      background-image: url('/images/pages/house-results/gryffindor-crest-en.png');
    }
    &.ravenclaw {
      background-image: url('/images/pages/house-results/ravenclaw-crest-en.png');
    }
    &.hufflepuff {
      background-image: url('/images/pages/house-results/hufflepuff-crest-en.png');
    }
    &.slytherin {
      background-image: url('/images/pages/house-results/slytherin-crest-en.png');
    }
  }

  .patronusImage {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 140%;
    height: 140%;
    z-index: 2;
  }

  .wandImage {
    position: absolute;
    top: 67%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 110%;
    height: 110%;
    z-index: 2;
  }

  .newsletterImage {
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    width: 75%;
    height: 75%;
    z-index: 2;
  }
}

.portraitContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  height: 75%;
  cursor: pointer;
  transition: all .15s ease-out;
}